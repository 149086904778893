import Logger from "./Logger"
import Config from "../config/Config"
import i18next from "i18next"

export default class Utils {
	
	static keyCodes = {
		enter: 13,
		backspace: 8,
		del: 46,
		escape: 27,
	}
	
	static isLoc = () => {
		return ['localhost', '127.0.0.1'].includes(window.location.hostname)
	}
	
	static checkHost = (targetHost) => {
		
		let currentHost = window.location.hostname
		
		// console.log('currentHost [' + typeof currentHost + '] = %o', currentHost)
		// console.log('targetHost [' + typeof targetHost + '] = %o', targetHost)
		
		if (Array.isArray(targetHost)) {
			return targetHost.includes(currentHost)
		} else {
			return currentHost == targetHost
		}
		
	}
	
	static isProd = () => {
		return Utils.checkHost(Config.prodHost)
	}
	
	static isDev = () => {
		return !this.isProd()
	}
	
	static allowFormFake = () => {
		return this.isLoc()
	}
	
	static setTitle = (pageName, user) => {
		
		let prefixData = []
		let mainData = []
		
		if (Utils.isLoc()) {
			prefixData.push('[loc]')
		}
		
		if (Utils.isDev()) {
			prefixData.push('[dev]')
		}
		
		const prefix = prefixData.join('')
		
		if (pageName) {
			mainData.push(pageName)
		}
		
		if (Config.projectName) {
			mainData.push(Config.projectName)
		}
		
		if (user) {
			
			mainData.push(user.fullName(1))
			
			if (user.role_name) {
				mainData.push(user.role_name)
			}
			
		}
		
		const title = mainData.join(' | ')
		
		document.title = prefix + ' ' + title
		
	}
	
	static pagesCount = (itemsCount, pageSize) => {
		return Math.ceil(itemsCount / pageSize)
	}
	
	static getUserToken = () => {
		
		let key = 'userToken'
		
		let fromHash = window.location.hash.split('#access_token=')[1]
		
		if (fromHash) {
			window.location.hash = ''
			localStorage.setItem(key, fromHash)
		}
		
		return localStorage.getItem(key) || null
		
	}
	
	static setUserToken = (userToken) => {
		return localStorage.setItem('userToken', userToken)
	}

	static isMobile = () => {
		return (function (a, b) {
			if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) window.location = b
		})(navigator.userAgent || navigator.vendor || window.opera, 'http://detectmobilebrowser.com/mobile')
	}
	
	// src: https://stackoverflow.com/questions/3710204/how-to-check-if-a-string-is-a-valid-json-string-in-javascript-without-using-try
	static isJsonString = (str) => {
		try {
			JSON.parse(str)
		} catch (e) {
			return false
		}
		return true
	}
	
	static parseJson = (jsonString, returnOnFail=null, callOnFail) => {
		
		const logName = 'Utils.parseJson'
		const logAllow = 1
		const logCollapsed = 0
		
		Logger.groupStart(logName, logAllow, logCollapsed)
		
		try {
			
			Logger.groupEnd(logAllow)
			
			return JSON.parse(jsonString)
			
		} catch (err) {
			
			Logger.log(err, 'err', logAllow)
			
			if (callOnFail) {
				callOnFail(err)
			}
			
			Logger.groupEnd(logAllow)
			
			return returnOnFail
			
		}
		
	}
	
	static axiosErrorYii2Alert = (axiosError) => {
		if (axiosError.response.data) {
			window.alert(axiosError.response.data.message)
		}
	}
	
	static backendBaseUrl = () => {
		return Config.backendBaseUrlMap[window.location.hostname]
	}
	
	static apiBaseUrl = (apiVersion = 1) => {
		// return this.isProd() ? Config.apiProdUrl : Config.apiDevUrl
		return this.backendBaseUrl() + `/api/v${apiVersion}`
	}
	
	static apiUrl(path, apiVersion = 1) {
		return this.apiBaseUrl(apiVersion)  + (path ? '/' + path : '')
	}
	
	static backendUrl(path, params={}) {
		
		let url = this.backendBaseUrl()
		
		if (path) {
			url += '/' + path
		}
		
		let paramsStringData = []
		
		Object.keys(params).forEach((key) => {
			let val = encodeURIComponent(params[key])
			paramsStringData.push(key + '=' + val)
		})
		
		let paramsString = paramsStringData.join('&')
		
		if (paramsString) {
			url += '?' + paramsString
		}
		
		return url
		
	}
	
	static debugString(data, sep=' | ') {
		
		if (!Utils.isDev()) {
			return ''
		}
		
		let out = []
		
		Object.keys(data).forEach((key) => {
			out.push(key + '=' + data[key])
		})
		
		return out.join(sep)
		
	}
	
	// src: https://blog.skay.dev/how-to-make-an-argument-required-in-javascript
	static required = () => {
		throw Error('argument is required')
	}
	
	// get all except methods
	static objAttrs = (obj, excludedAttrs=[]) => {
		const x = []
		Object.keys(obj).forEach((attr) => {
			if (!excludedAttrs.includes(attr)) {
				const val = obj[attr]
				if (typeof val !== 'function') {
					x.push(attr)
				}
			}
		})
		return x
	}
	
	static formData = (model, excludedAttrs=[]) => {
		
		let formData = new FormData()
		
		let attrs = Utils.objAttrs(model, excludedAttrs)
		
		attrs.forEach((attr) => {
			
			let val = model[attr]
			
			if (typeof val === 'object') {
				val = JSON.stringify(val)
			}
			
			formData.set(attr, val)
			
		})
		
		return formData
		
	}
	
	static matchLeastOne = (string, patterns) => {
		
		let ok = null
		
		for (let i=0; i < patterns.length; i++) {
			
			let regex = patterns[i]
			
			ok = string.toString().match(regex)
			
			if (ok) {
				break
			}
			
		}
		
		return ok !== null
		
	}
	
	static checkModel = (model, checks, useAlert=0) => {
		
		const logName = 'Utils.checkModel'
		const logAllow = 1
		const logCollapsed = 0
		
		Logger.groupStart(logName, logAllow, logCollapsed)
		
		let msgs = []
	
		checks.forEach((check) => {
			
			if (typeof check == 'function') {
				
				let checkMsgs = check(model)
				
				Logger.log(checkMsgs, 'checkMsgs', logAllow)
				
				msgs = msgs.concat(checkMsgs)
				
			} else {
				
				let checker = check.checker
				let msg = check.msg
				
				if (checker) {
					if (!checker(model)) {
						msgs.push(msg)
					}
				} else {
					let attr = check.attr
					let regex = check.regex
					if (!Utils.matchLeastOne(model[attr], regex)) {
						msgs.push(msg)
					}
				}
				
			}
			
		})
		
		Logger.log(msgs, 'errors', logAllow)
		
		if (useAlert && msgs.length > 0) {
			
			let alertMsgData = [
				i18next.t("Пожалуйста исправьте следующие ошибки:"),
				'',
			]
			
			msgs.forEach((msg) => {
				alertMsgData.push('> ' + msg)
				alertMsgData.push('')
			})
			
			if (useAlert) {
				window.alert(alertMsgData.join("\n"))
			}
			
		}
		
		Logger.groupEnd(logAllow)
		
		return msgs
	}
	
	static isObject = (x) => {
		return typeof x === 'object'
	}
	
	static isArray = (x) => {
		return Array.isArray(x)
	}
	
	static formatNumber = (number, options = {}, locale = 'ru-RU') => {
		return Intl.NumberFormat(locale, options).format(number)
	}
	
	static setLang = (langAlias) => {
		i18next.changeLanguage(langAlias)
		localStorage.setItem('langAlias', langAlias)
	}
	
	static currentLangCode = (langAlias) => {
		return i18next.language
	}
	
	static initLang = () => {
		
		const logName = 'Utils.initLang'
		const logAllow = 0
		const logCollapse = 0
		
		Logger.groupStart(logName, logAllow, logCollapse)
		
		const defaultLangAlias = Config.defaultLangAlias
		Logger.log(defaultLangAlias, 'defaultLangAlias', logAllow)
		
		const storedLangAlias = localStorage.getItem('langAlias')
		Logger.log(storedLangAlias, 'storedLangAlias', logAllow)
		
		const storedLang = Config.langs[storedLangAlias]
		Logger.log(storedLang, 'storedLang', logAllow)
		
		const browserLangAlias = navigator.language
		Logger.log(browserLangAlias, 'browserLangAlias', logAllow)
		
		const browserLang = Config.langs[browserLangAlias]
		Logger.log(browserLang, 'browserLang', logAllow)
		
		const currentLangAlias = storedLangAlias
			? storedLang
				? storedLangAlias
				: defaultLangAlias
			: browserLang
				? browserLangAlias
				: defaultLangAlias
		
		Logger.log(currentLangAlias, 'currentLangAlias', logAllow)
		
		this.setLang(currentLangAlias)
		
		Logger.groupEnd(logAllow)
		
		return currentLangAlias
		
	}
	
	// src: https://ihateregex.io/expr/phone/
	static phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
	static emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
	
	static checkPhone = (phone) => {
		let matches = phone.match(this.phoneRegex)
		return matches && matches.length > 0
	}
	
	static checkEmail = (email) => {
		let matches = email.match(this.emailRegex)
		return matches && matches.length > 0
	}
	
}