import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import Order from '../../models/Order'
import Utils from '../../helpers/Utils'
import Logger from '../../helpers/Logger'
import Config from '../../config/Config'
import axios from 'axios'

class LandingOrderForm extends React.Component {

	constructor(props) {
		
		const logName = 'LandingOrderForm.constructor'
		const logAllow = 1
		const logCollapse = 0
		
		Logger.groupStart(logName, logAllow, logCollapse)
		
		super(props)
		
		let model = props.model ? {...props.model} : new Order()
		
		model.serviceTypeName = props.serviceTypeName
		
		this.state = {
			model: model,
		}
		
		if (Utils.allowFormFake()) {
			model.contactName = '*** TEST ***'				
			model.phone = '89876543210'
			model.email = 'test.test@test.test'
		}
		
		Logger.groupEnd(logAllow)
		
	}
	
	isFilled = () => {
		
		const logName = 'ClaimForm.isFilled'
		const logAllow = 0
		const logCollapse = 0
		
		Logger.groupStart(logName, logAllow, logCollapse)
		
		let model = this.state.model
		
		let phone = model.phone
		let phoneIsValid = phone ? Utils.checkPhone(phone) : false
		// let phoneIsValid = phone
		Logger.log(phone, 'phone', logAllow)
		
		let email = model.email
		let emailIsValid = email ? Utils.checkEmail(email) : false
		// let emailIsValid = email
		Logger.log(email, 'email', logAllow)
		
		Logger.groupEnd(logAllow)
		
		return phoneIsValid || emailIsValid
		
	}
	
	inputChange = (event) => {
		
		const model = this.state.model;
		
		const field = event.target;
		const value = field.type === 'checkbox' ? field.checked : field.value;
		const attr = field.getAttribute('name');
		
		model[attr] = value;
		
		this.setState({
			model: model,
		})
		
	}
	
	submit = (event) => {
		
		event.preventDefault()
		
		const form = event.currentTarget
		const model = this.state.model
		
		if (form.checkValidity()) {
			
			const isFilled = this.isFilled()
			
			this.props.preloader?.show()
			
			if (isFilled) {
				
				axios({
					method: 'post',
					url: Utils.backendUrl(`api/claim`),
					data: model,
					params: {
						'apiKey': Config.backendApiKey,
					},
				}).then((response) => {
					
					const logName = 'ClaimForm.submit.ajax.done'
					const logAllow = 1
					const logCollapse = 0
					
					Logger.groupStart(logName, logAllow, logCollapse)
					
					this.props.preloader?.hide()
					
					// Logger.log(response, 'response', logAllow)
					Logger.log(response.data, 'response.data', logAllow)
					
					if (response.data == 1) {
						window.alert(i18next.t("Спасибо! Заявка успешно отправлена"))
					} else {
						window.alert(i18next.t("Извините! На свервере неполадки. Пожалуйста, обратитесь по указанным контактам"))
					}
					
					Logger.groupEnd(logAllow)
					
				}).catch((axiosError) => {
					
					this.props.preloader?.hide()
					
					window.alert(axiosError)
					
					console.log(`axiosError = %o`, axiosError)
					
				})
				
			} else {
				
				this.props.preloader?.hide()
				
				window.alert(i18next.t("Пожалуйста, укажите корректный номер телефона и / или адрес электронной почты"))
				
			}
			
		}
		
	}
	
	componentDidMount() {
		// ajax
	}

	render() {
		
		const model = this.state.model
		
		return (
			
			<form
				className={'order-form'}
				onSubmit={this.submit}
			>
				
				<div className={`form-name h3`}>
					{i18next.t("Оставить заявку")}
				</div>
				
				<div className={`form-group`}>
					<label>{i18next.t("Имя")}</label>
					<input 
						id={`name`} 
						name={`contactName`}
						className={`form-control`}
						onChange={this.inputChange}
						value={model.contactName}
					/>
				</div>
				
				<div className={`row`}>
					
					<div className={`col-md-6`}>
						<div className={`form-group`}>
							<label>{i18next.t("Телефон")}</label>
							<input 
								id={`phone`} 
								name={'phone'}
								onChange={this.inputChange}
								value={model.phone}
								type={'tel'}
								className={`form-control`}
							/>
						</div>
					</div>
					
					<div className={`col-md-6`}>
						<div className={`form-group`}>
							<label>{i18next.t("E-mail")}</label>
							<input 
								id={`email`} 
								className={`form-control`}
								type={'email'}
								name={'email'}
								onChange={this.inputChange}
								value={model.email}
							/>
						</div>
					</div>
					
				</div>
				
				<button
					className={`my-btn submit-btn`}
					type={'submit'}
				>{i18next.t("Отправить")}</button>
				
			</form>
			
		)
		
	}
	
}

LandingOrderForm.propTypes = {
	preloader: PropTypes.object,
	serviceTypeName: PropTypes.any,
}

LandingOrderForm.defaultProps = {}

export default LandingOrderForm