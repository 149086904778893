import './LandingPrograms.css'

import React from 'react'
import PropTypes from 'prop-types'

import i18next from 'i18next'
import LandingOrderForm from './LandingOrderForm'

import Config from '../../config/Config'
import Order from '../../models/Order'

class LandingPrograms extends React.Component {

	constructor(props) {
		super(props)
		this.state = {}
	}
	
	componentDidMount() {
		// ajax
	}

	render() {
		
		return (
			
			<section className={'programs'}>
				
				{Config.programs.map((program, programIndex) => {
					
					let programNum = programIndex + 1
					
					return (
						
						<section
							id={program.alias}
							key={`program-${programNum}`}
							className={`program overlay program-${program.alias} program-${programNum} program-color-${program.color}`}
						>
							
							<div className={`container`}>
								
								<div className={`head`}>
									
									<h2 className={`name program-name`}>
										{program.name}
									</h2>
									
								</div>
								
								<div className={`body`}>
								
									<div className={`row`}>
										
										<div className={`text-col col-md-6`}>
											
											<div className={`hours`}>
												{program.hours} {i18next.t("академических часа")}
											</div>
											
											<div className={`about`}>
												
												{program.about.map((aboutText, aboutTextIndex) => {
													
													return (
														<p className={`item about-item`}>{aboutText}</p>
													)
													
												})}
												
											</div>
											
											<div className={`form-box`}>
												
												<LandingOrderForm
													preloader={this.props.preloader}
													serviceTypeName={'ИИРСИ ДПО / Программа / ' + program.shortName}
												/>
												
											</div>
											
										</div>
										
										<div className={`img-col col-md-6`}>
											<img className={`img program-img`}/>
										</div>
										
									</div>
									
								</div>
								
							</div>
							
						</section>
						
					)
					
				})}
				
			</section>
			
		)
		
	}
	
}

LandingPrograms.propTypes = {
	preloader: PropTypes.object,
}

LandingPrograms.defaultProps = {}

export default LandingPrograms