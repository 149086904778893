import './LandingHeader.css'
import './LandingHeader_XS.css'

import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'
import LandingNavbar from './LandingNavbar'
import Config from '../../config/Config'

class LandingHeader extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }
    
    componentDidMount() {
        // ajax
    }

    render() {
        
        return (
            
            <header>
					
                <LandingNavbar/>
                
                <div className={`container`}>
                    
                    <div className={`main`}>
                    
                        <h1 
                            className={`brand-name d-inline-block`} 
                            title={`Институт искусственного интеллекта, робототехники и системной инженерии`}
                        >ИИРСИ</h1>
                        
                        <div className={`brand-about`}>
                            Дополнительное
                            <br/>профессиональное
                            <br/>образование
                        </div>
                        
                        <div className={`contents d-none d-xl-block`}>
                            
                            {Config.programs.map((program) => {
                                return (
                                    <a 
                                        key={program.alias}
                                        href={'#' + program.alias}
                                        className={[
                                            'my-btn',
                                            'contents-btn',
                                        ].join(' ')}
                                    >{program.shortName}</a>
                                )
                            })}
                            
                        </div>			
                        
                    </div>
                    
                </div>
                
            </header>
            
        )
        
    }
    
}

LandingHeader.propTypes = {
    preloader: PropTypes.object,
}

LandingHeader.defaultProps = {}

export default LandingHeader