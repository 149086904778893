import Order from "../models/Order";

export default class Config {

	static projectName = `ИИРСИ КФУ - Дополнительное профессиональное образование (ДПО)`
	
	static version = '1.0'
	
	static versionDatetime = '2023-11-30 15:00:00'

	static backendApiKey = 'vA9PmksxzqAFTe6'
	
	static prodHost = 'airse-dpo.ru'
	
	static devBackendUrl = 'https://core-dev-wbwuz9u38u.xn--80aahcyf0advcvd.xn--p1ai'
	static prodBackendUrl = 'https://core.xn--80aahcyf0advcvd.xn--p1ai'
	
	static backendBaseUrlMap = {
		'localhost': this.devBackendUrl,
		'airse-dpo.ru': this.prodBackendUrl,
	}
	
	static defaultLangCode = 'ru-RU'
	
	static langs = {
		
		'ru-RU': {
			name: 'Русский',
		},
		
		'en-GB': {
			name: 'English',
		},
		
	}

	static permissions = {
		
		'debugInfo': [
			'owner',
			'admin',
		],
		
	}
	
	static contacts = [
			
		{
			name: '',
			phone: '+7 (843) 233-75-76',
			email: '',
		},
		
		{
			name: '',
			phone: '+7 (960) 030-10-70',
			email: '',
		},
		
		{
			name: '',
			phone: '',
			email: 'airse@kpfu.ru',
		},
		
	]
	
	static programs = [
		
		{
			alias: 'prompt',
			orderServiceTypeAlias: Order.serviceTypeAliasProgramPromptEnginiring,
			name: '«Prompt-engineering»',
			shortName: 'Prompt-engineering',
			color: 'white',
			hours: 72,
			about: [
				'Нейросети — это современный инструмент для анализа данных и решения различных задач. Они используются в бизнесе, науке, медицине и многих других областях.',
				'В курсе “Prompt-engineering” вы изучите основы работы нейросетей и научитесь применять их на практике. Вы получите знания о подготовке данных, оптимизации и интерпретации результатов.',
				'Благодаря курсу вы сможете улучшить эффективность своих бизнес-процессов и повысить качество принимаемых решений.',
			]
		},
		
		{
			alias: 'corp-control',
			orderServiceTypeAlias: Order.serviceTypeAliasProgramCorpControl,
			// name: '«Механизмы корпоративного управления и коммерциализация интеллектуальной собственности»',
			// shortName: 'Механизмы корпоративного управления',
			name: '«Стартап менеджмент и управление проектами»',
			shortName: 'Стартап менеджмент и управление проектами',
			color: 'black',
			hours: 72,
			about: [
				'Учебный курс направлен на обучение защите и использованию интеллектуальной собственности. Включает основные понятия, виды и роль интеллектуальной собственности в экономике.',
				'Курс уделяет внимание коммерциализации интеллектуальной собственности. Учащиеся научатся проводить патентный поиск и регистрировать объекты интеллектуальной собственности.',
				'Курс разработан для специалистов из разных областей и помогает получить навыки в области коммерциализации интеллектуальной собственности.',
				'По завершению курса вы приобретете знания и навыки для работы \nв сфере инноваций и защиты интеллектуальной собственности.',
			]
		},
		
		{
			alias: 'qa',
			orderServiceTypeAlias: Order.serviceTypeAliasProgramQa,
			name: '«Стандартизация и автоматизация деятельности по контролю качества на предприятиях реального сектора экономики и оборонного комплекса»',
			shortName: 'Деятельность по контролю качества',
			color: 'white',
			hours: 72,
			about: [
				'Курс обучает методам и инструментам стандартизации \nи автоматизации контроля качества.',
				'Цель курса — обучить специалистов принципам обеспечения качества товаров и услуг в соответствии со стандартами \nи законодательством.',
				'Курс готовит профессионалов для реального сектора экономики \nи оборонной отрасли.',
				'По окончании курса специалисты получают знания и навыки \nдля работы в области стандартизации и контроля качества и могут повысить свою конкурентоспособность.',
			]
		},
		
	]
	
}