import PropTypes from 'prop-types'
import React from 'react'

import i18next from 'i18next'
import { Navbar } from 'react-bootstrap'
import Config from '../../config/Config'

import './LandingNavbar.css'
import './LandingNavbar_XS.css'

class LandingNavbar extends React.Component {

	constructor(props) {
		super(props)
		this.state = {}
	}
	
	componentDidMount() {
		// ajax
	}

	render() {
		
		return (
			
			<Navbar expand={'lg'}>
				
				<div className={`container`}>
					
					<Navbar.Brand>
						<a href={`/`}><img className={`airse-logo`}/></a>
					</Navbar.Brand>	
					
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					
					<Navbar.Collapse id="basic-navbar-nav">
						
						<ul class="navbar-nav">
							{Config.programs.map((program) => {
								return (
									<li class="nav-item">
										<a 
											href={'#' + program.alias} 
											className={`nav-link corporate-control`}
										>{program.shortName}</a>
									</li>
								)
							})}
						</ul>
						
						<div className={`ms-auto`}>
							
							<div className={`contacts`}>
								
								{Config.contacts.map((contact) => {
									
									return (
										
										<div className={`contact`}>
											
											{(contact.phone) && (
												<a href={`tel:${contact.phone}`} className={`contact-link contact-phone-link`}>{contact.phone}</a>
											)}
											
											{(contact.email) && (
												<a href={`mailto:${contact.email}`} className={`contact-link contact-email-link`}>{contact.email}</a>
											)}
											
										</div>
										
									)
									
								})}
								
							</div>
							
						</div>
						
					</Navbar.Collapse>
					
				</div>
				
			</Navbar>
			
		)
		
	}
	
}

Navbar.propTypes = {
	preloader: PropTypes.object,
}

Navbar.defaultProps = {}

export default LandingNavbar