import React from 'react';
import PropTypes from "prop-types";

import './Preloader.css';

export default class Preloader extends React.Component {
	
	render() {
		return this.props.show ? (
			<div className={'preloader'} id={this.props.id}/>
		) : '';
	}
	
}

Preloader.propTypes = {
	show: PropTypes.bool,
};

Preloader.defaultProps = {
	show: false,
};