import './Landing.css'
import './Landing_XS.css'

import i18next from 'i18next'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router'

import LandingHeader from './LandingHeader'
import LandingAbout from './LandingAbout'
import LandingProblem from './LandingProblem'
import LandingSolve from './LandingSolve'
import LandingPrograms from './LandingPrograms'
import LandingContacts from './LandingContacts'

class Landing extends React.Component {

	constructor(props) {
		super(props)
		this.state = {}
	}
	
	componentDidMount() {
		// ajax
	}

	render() {
		
		return (
			
			<div className={'landing'}>
				
				<LandingHeader/>
				
				<LandingAbout/>
				
				<LandingProblem/>
				
				<LandingSolve/>
				
				<LandingPrograms
					preloader={this.props.preloader}
				/>
				
				<LandingContacts/>
				
			</div>
			
		)
		
	}
	
}

Landing.propTypes = {
	preloader: PropTypes.object,
}

Landing.defaultProps = {}

export default withRouter(Landing)