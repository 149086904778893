import Utils from "./Utils";

export default class Logger {
	
	static get enabled() {
		return Utils.isDev();
	};
	
	static log(val, name = null, logAllow = 1, logName = null) {
		if (this.enabled) {
			if (logAllow && console && console.log) {
				let prefix = (logName ? logName + ' : ' : '') + (name ? name + ' [' + typeof val + '] = ' : '');
				console.log(prefix + '%o', val);
			}
		}
	}
	
	static groupStart(groupName, allow = 1, collapsed = 0) {
		if (this.enabled) {
			if (allow && console) {
				if (collapsed && console.groupCollapsed) {
					console.groupCollapsed(groupName);
				} else if (console.group) {
					console.group(groupName);
				}
			}
		}
	}
	
	static groupEnd(allow) {
		if (this.enabled) {
			if (allow && console && console.groupEnd) {
				console.groupEnd();
			}
		}
	}
	
}
