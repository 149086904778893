import './LandingSolve.css'

import React from 'react'
import PropTypes from 'prop-types'

import i18next from 'i18next'

class LandingSolve extends React.Component {

	constructor(props) {
		super(props)
		this.state = {}
	}
	
	componentDidMount() {
		// ajax
	}

	render() {
		
		let items = [
			
			{
				// icon: './img/solve-1.svg',
				text: "Профессиональные преподаватели,\nв их числе кандидаты и доктора наук",
			},
			
			{
				// icon: './img/solve-2.svg',
				text: "Современное оборудование",
			},
			
			{
				// icon: './img/solve-3.svg',
				text: 'Доступ к новейшим\nинформационным ресурсам',
			},
			
			{
				// icon: './img/solve-4.svg',
				text: 'Удобная форма оплаты',
			},
			
			{
				// icon: './img/solve-5.svg',
				text: 'Комфортные условия для учебы',
			},
			
		]
		
		return (
			
			<section className={'solve'}>
				
				<div className={`head`}>
					
					<div className={`container`}>
						{i18next.t("Дополнительное профессиональное образование института ИИРСИ – решение текущих проблем!")}                        
					</div>
					
				</div>
				
				<div className={`body`}>
					
					<div className={`container`}>
						
						<div className={`row outer-row`}>
							
							<div className={`col-md-1`}>
								
							</div>
							
							<div className={`col-md-10 outer-col align-self-center`}>
								
								<div className={`row inner-row justify-content-center`}>
									
									{items.map((item, itemIndex) => {
										
										let itemNum = itemIndex + 1
										
										return (
											
											<div key={`item-${itemIndex}`} className={`col-md-6 item-col`}>
										
												<div className={`item item-${itemNum}`}>
													
													<div className={`row align-items-center`}>
														
														<div className={`icon-col col-auto`}>
															<div 
																className={`icon-box`} 
																style={{
																	// backgroundImage: `url('${item.icon}')`,
																	// backgroundImage: `url(${item.icon})`,
																}}
															></div>
														</div>
														
														<div className={`text-col col`}>
															<div className={`text`}>
																{item.text}
															</div>
														</div>
														
													</div>
													
												</div>
												
											</div>
											
										)
										
									})}
									
								</div>
								
							</div>
							
						</div>
						
					</div>
					
				</div>
				
			</section>
			
		)
		
	}
	
}

LandingSolve.propTypes = {
	preloader: PropTypes.object,
}

LandingSolve.defaultProps = {}

export default LandingSolve