import i18n from "i18next";
import {initReactI18next} from "react-i18next";

const resources = {
	
	'ru-RU': {
		
		translation: {
			'Main page': 'Главная страница',
			'Other page': 'Другая страница',
		},
		
	},
	
}

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		
		resources,
		
		// lng: 'en-GB',
		// lng: 'ru-RU',
		// lng: 'en-US',
		// lng: navigator.language,
		
		// languages: ['ru-RU', 'en-GB'],
		
		// fallbackLng: "en-GB",
		
		keySeparator: false, // we do not use keys in form messages.welcome
		
		interpolation: {
			escapeValue: false, // react already safes from xss
		}
		
	})

export default i18n