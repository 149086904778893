import './LandingProblem.css'

import React from 'react'
import PropTypes from 'prop-types'

import i18next from 'i18next'

class LandingProblem extends React.Component {

	constructor(props) {
		super(props)
		this.state = {}
	}
	
	componentDidMount() {
		// ajax
	}

	render() {
		
		let items = [
			
			{
				// icon: 'img/problem-icon-1.svg',
				name: 'Разнообразие и качество программ',
				about: 'В настоящее время существует огромное количество программ дополнительного профессионального обучения, предлагающих различные уровни и направления. Однако не все из них соответствуют высоким стандартам качества и могут обеспечить полноценное образование.',
			},
			
			{
				// icon: 'img/problem-icon-2.svg',
				name: 'Недостаток квалифицированных преподавателей',
				about: 'Преподаватели с высоким уровнем профессионализма и опыта могут быть ограничены, что затрудняет обеспечение качественного и эффективного обучения.',
			},
			
			{
				// icon: 'img/problem-icon-3.svg',
				name: 'Необходимость постоянного обновления знаний',
				about: 'В связи с быстрым развитием технологий и изменениями в профессиональной сфере, дополнительное профессиональное образование должно постоянно адаптироваться и предлагать новые возможности для обучения и развития.',
			},
			
		]
		
		return (
			
			<section id={'problem'} className={'landing-problem'}>
				
				<div className={`container`}>
					
					<div className={`head`}>
						
						<h2 className={`section-header`}>
							{i18next.t("Проблема")}
						</h2>
						
					</div>
				
					<div className={`body`}>
					
						<div className={`row`}>
							
							<div className={`col1 card-col col-xl-6`}>
								
								{items.map((item, itemIndex) => {
									
									let itemNum = itemIndex + 1
									
									return (
										
										<div className={`card item item${itemNum} problem-item landing-problem-item`}>
								
											<div className={`card-body`}>
												
												<div className={`icon-box`}>
													<img className={`icon`} style={{
														// content: `url('${item.icon}')`,
													}}/>
												</div>
												
												<div className={`name`}>
													{item.name}
												</div>
												
												<div className={`about`}>
													{item.about}
												</div>
												
											</div>
											
										</div>
									)
									
								})}
								
							</div>
							
							<div className={`col2 img-col col-xl-6 align-self-center`}>
								
								<img className={`img align-self-middle d-none d-md-inline`}/>
								
							</div>
							
						</div>
						
						
					</div>
					
				</div>
				
			</section>
			
		)
		
	}
	
}

LandingProblem.propTypes = {
	preloader: PropTypes.object,
}

LandingProblem.defaultProps = {}

export default LandingProblem