import 'bootstrap/dist/css/bootstrap.min.css';
import './main.css';
import './utils.css';

import PropTypes from 'prop-types';
import React from 'react';
import { Route, Switch } from "react-router-dom";

import { withTranslation } from "react-i18next";
import './i18n';

import Utils from './helpers/Utils';
import Landing from './components/landing/Landing';
import Bootstrap5BreakpointIndicator from './components/utils/Bootstrap5BreakpointIndicator'
import Preloader from './components/utils/Preloader/Preloader'

class App extends React.Component {

	constructor(props) {
		
		super(props)
		
		Utils.initLang()
		
		Utils.setTitle()
		
		this.state = {
			preloaderIsShown: false,
		}
		
	}
	
	preloader = {
		show: () => {
			this.setState((prevState) => {
				return {
					preloaderIsShown: true,
				};
			});
		},
		hide: () => {
			this.setState((prevState) => {
				return {
					preloaderIsShown: false,
				};
			});
		},
	};
	
	componentDidMount() {
		// ajax
	}

	render() {
		
		return (
			
			<div className={'app'}>
				
				<div className={`page`}>
				
					<Switch>
				
						<Route exact path={'/'}>
							<Landing
								preloader={this.preloader}
							/>
						</Route>
					
					</Switch>
					
				</div>
				
				<Preloader
					show={this.state.preloaderIsShown}
				/>
				
				{(Utils.isDev()) && (
					<Bootstrap5BreakpointIndicator/>
				)}
				
			</div>
			
		)
		
	}
	
}

App.propTypes = {
	preloader: PropTypes.object,
}

App.defaultProps = {}

export default withTranslation()(App)