import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'

import './LandingAbout.css'
import './LandingAbout_XS.css'

class LandingAbout extends React.Component {

	constructor(props) {
		super(props)
		this.state = {}
	}
	
	componentDidMount() {
		// ajax
	}

	render() {
		
		let aboutItems = [
			'Национальной программы «Цифровая экономика»',
			'Национальной стратегии по развитию искусственного интеллекта',
			'Национального проекта «Беспилотные авиационные системы»',
			'Национального проекта «Малое и среднее предпринимательство и поддержка индивидуальной предпринимательской инициативы»',
			'Национального проекта «Малое и среднее предпринимательство и поддержка индивидуальной предпринимательской инициативы»',
			'Федерального проекта «Платформа университетского технологического предпринимательства»',
		]
		
		let contactLinks = [
			'https://kpfu.ru/engineer',
			'https://t.me/airandsi',
		]
		
		return (
			
			<section 
				id={`about`} 
				className={`about`}
			>
				
				<div className={`container`}>
					
					<div className={`head`}>
						<h2 className={`section-header`}>
							{i18next.t('Институт искусственного интеллекта, робототехники и системной инженерии')}
						</h2>
					</div>
					
					<div className={`body`}>
						
						<div className={`main-img-box`}>
							<img className={`main-img`}/>
						</div>
						
						<div className={`info`}>
							
							<div className={`row`}>
								
								<div className={`col1 col-xl-7 col-lg-6`}>
									
									<div className={`card`}>
										
										<div className={`h3`}>
											{i18next.t("Выполняем проекты, реализуемые в рамках:")}
										</div>
										
										<ul>
											{aboutItems.map((aboutItem) => {
												return (
													<li>{aboutItem}</li>
												)
											})}
										</ul>
										
									</div>
									
								</div>
								
								<div className={`col2 col align-self-end`}>
									
									<div className={`card`}>
										
										<div className={`card-header`}>
											{i18next.t("Ещё больше информации о нас")}
										</div>
										
										<div className={`links`}>
											
											{contactLinks.map((contactLink) => {
												return (
													<div className={`link-box`}>
														<a href={contactLink} target={'_blank'} className={`link`}>{contactLink}</a>
													</div>
												)
											})}
											
										</div>
										
									</div>
									
								</div>
								
							</div>
							
						</div>
						
					</div>
					
				</div>
				
			</section>
			
		)
		
	}
	
}

LandingAbout.propTypes = {
	preloader: PropTypes.object,
}

LandingAbout.defaultProps = {}

export default LandingAbout