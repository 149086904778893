import './LandingContacts.css'

import React from 'react'
import PropTypes from 'prop-types'
import i18next from 'i18next'

import Config from '../../config/Config'

class LandingContacts extends React.Component {

	constructor(props) {
		super(props)
		this.state = {}
	}
	
	componentDidMount() {
		// ajax
	}

	render() {
		
		return (
			
			<section id={'contacts'} className={'contacts overlay'}>
				
				<div className={`container`}>
					
					<div className={`head`}>
						
						<h2 className={`section-header`}>
							{i18next.t("Контакты")}
						</h2>
						
					</div>
					
					<div className={`body`}>
						
						{Config.contacts.map((contact) => {
									
							return (
								
								<div className={`contact`}>
									
									{(contact.phone) && (
										<a 
											href={`tel:${contact.phone}`} 
											className={`contact-link contact-phone-link`}
										>{contact.phone}</a>
									)}
									
									{(contact.email) && (
										<a 
											href={`mailto:${contact.email}`} 
											className={`contact-link contact-email-link`}
										>{contact.email}</a>
									)}
									
								</div>
								
							)
							
						})}
						
					</div>
					
				</div>
				
			</section>
			
		)
		
	}
	
}

LandingContacts.propTypes = {
	preloader: PropTypes.object,
}

LandingContacts.defaultProps = {}

export default LandingContacts