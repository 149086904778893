import React from 'react';
import "./Bootstrap5BreakpointIndicator.css";

export default class Bootstrap5BreakpointIndicator extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	render() {
		
		let breakPoints = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
		
		return (
			
			<div className={'BootstrapBreakpointIndicator'}>
				
				{breakPoints.map((breakpointAlias) => {
					
					let cssClassArray = ['item'];
					
					if (breakpointAlias == 'xs') {
						cssClassArray.push('d-block');
					} else {
						cssClassArray.push('d-none');
						cssClassArray.push('d-' + breakpointAlias + '-block');
					}
					
					breakPoints.forEach(x2 => {
						if (breakpointAlias != x2) {
							cssClassArray.push('d-' + x2 + '-none');
						}
					});
					
					return (
						<div key={'breakpoint-' + breakpointAlias} className={cssClassArray.join(' ')}>{breakpointAlias}</div>
					);
					
				})}
				
			</div>
			
		);
		
	}
	
}

Bootstrap5BreakpointIndicator.propTypes = {};

Bootstrap5BreakpointIndicator.defaultProps = {};